import React, { FunctionComponent, useRef, useEffect } from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { gsap } from "gsap";
import { PortableText } from "../../types/SanityTypes";
import "./styles.scss";

export interface TickerTextInterface {
  headings: {
    primaryText: string;
    secondaryText: string;
  };
  _rawText: PortableText;
  // For Preview
  text?: PortableText;
}

const TickerText: FunctionComponent<TickerTextInterface> = ({ headings, _rawText, text }) => {
  const ticker1 = useRef(null);
  const ticker2 = useRef(null);
  const mediaQuery = (typeof window !== "undefined" && window.matchMedia("(prefers-reduced-motion: reduce)")) || false;

  useEffect(() => {
    if (!mediaQuery.matches) {
      handleTickerAnimation(ticker1.current, -1230, 0);
      handleTickerAnimation(ticker2.current, 0, -1230);
    }
    mediaQuery.addEventListener("change", () => {
      if (!mediaQuery.matches) {
        handleTickerAnimation(ticker1.current, -1230, 0);
        handleTickerAnimation(ticker2.current, 0, -1230);
      }
    });
  }, [ticker1, ticker2]);

  function handleTickerAnimation(element: any, start: number, end: number) {
    gsap.fromTo(element, { x: start }, { x: end, ease: "none", repeat: -1, duration: 20 });
  }

  const textBody = _rawText || text;

  return (
    <section className="moving-text">
      <div className="ticker row1" aria-hidden="true" ref={ticker1}>
        {headings.primaryText} <span>{headings.secondaryText}</span> {headings.primaryText}{" "}
        <span>{headings.secondaryText}</span> {headings.primaryText} <span>{headings.secondaryText}</span>{" "}
        {headings.primaryText} <span>{headings.secondaryText}</span>
      </div>

      <div className="ticker row2" aria-hidden="true" ref={ticker2}>
        <span>{headings.primaryText}</span> {headings.secondaryText} <span>{headings.primaryText}</span>{" "}
        {headings.secondaryText} <span>{headings.primaryText}</span> {headings.secondaryText}{" "}
        <span>{headings.primaryText}</span> {headings.secondaryText}
      </div>
      <h2 className="sr-only">
        {headings.primaryText} {headings.secondaryText}
      </h2>

      {/* IF there is ticker copy... */}

      <Container fluid>
        <Row>
          <Col lg={{ span: 6, offset: 1 }} sm={{ span: 10, offset: 2 }}>
            {/* divider needs theme color */}
            <hr className="divider" />
            <div className="ticker-copy">{textBody && <BlockContent blocks={textBody} />}</div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TickerText;
